import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/custom-fonts.css'
import './assets/css/image-zoom.css';
// import './index.css';
// import './assets/css/style.css';
// import './assets/css/slicknav.css';
// import './assets/css/slick.css';
// import './assets/css/font-awesome.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/free-solid-svg-icons';
// import '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';

// library.add(fab);
import '@fortawesome/fontawesome-free/css/all.css';

// import '@fortawesome'

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/home';
import ProductList from './pages/products/productList';
import ProductDetails from './pages/products/productDetails';
import SignIN from './pages/authentication/login';
import ProductListingPage from './pages/products/productListing';
import CustomCard from './pages/products/test';
import store from './store';
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProductDescriptionPage from './pages/products/muiProductDetails';
import WishList from './pages/wishLists/wishList';
import Cart from './pages/carts/cart';
import ComingSoon from './pages/comingSoon/comingSoon';

const darkMode = true;

const theme = createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
  },
});

const router = createHashRouter([
  {
    path: "/temp",
    exactPath: true,
    element: <Home />,
  },
  {
    path: "/",
    exactPath: true,
    element: <ComingSoon />,
  },
  {
    path: "/products",
    element: <ProductList />,
  },
  {
    path: "/product-list",
    element: <ProductListingPage />,
  },
  {
    path: "/test",
    element: <CustomCard />,
  },
  {
    path: "/sign-in",
    element: <SignIN />,
  },
  {
    path: "/product/:slug",
    element: <ProductDescriptionPage/>
  },
  {
    path: "/wishlist",
    element: <WishList/>
  },
  {
    path: "/cart",
    element: <Cart/>
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
    <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  </Provider>
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
