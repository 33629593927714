import { createSlice } from '@reduxjs/toolkit'

export const wishListSlice = createSlice({
    name: "wishListReducer",
    initialState: {
        wishLists: []
    },
    reducers: {
        addToWish: (state, action) => {
            state.wishLists.push(action.payload);
        },
        removeFromWish: (state, action) => {
            state.wishLists = state.wishLists.filter(item => item !== action.payload);
        }
    }
});


export const { addToWish, removeFromWish } = wishListSlice.actions;

export default wishListSlice.reducer;