import React, {useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Stack,
  TextField,
  Divider,
  Link,
  IconButton,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { useSelector, useDispatch } from "react-redux";
import { handleClose } from '../../store/loginModalReducer';

const VerticalModalSignIn = () => {
  const { open } = useSelector((state)=> state.loginModal);
  const dispatch = useDispatch();
  const [signUp, setSignUp] = useState(false);

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Sign In
      </Button> */}
      <Dialog open={open} onClose={() => dispatch(handleClose())} maxWidth="xs">
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <DialogTitle sx={{ textAlign: 'center' }}>
             <img src={require("../../assets/img/logo_1.png")}  alt="Logo" width="100" height="100" />
              <Typography variant="h5">
              {signUp ? "Sign In" : "Sign Up"}
              </Typography>
            </DialogTitle>
            <Stack spacing={2}>
              <TextField label="Email" variant="outlined" />
              <TextField label="Password" variant="outlined" type="password" />
              <Button variant="contained" color="primary" fullWidth>
                {!signUp ? "Sign Up" : "Sign In"}
              </Button>
            </Stack>
            <Divider />
            <Typography variant="body2" align="center">
              {!signUp ? "Or Sign Up With" : "Or Sign In With"}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
              <Button variant="contained" color="primary">
                Google
              </Button>
              <Button variant="contained" color="primary">
                Facebook
              </Button>
            </Box>
            <Link href="#" variant="body2" align="center">
              Forgot Password?
            </Link>
            <Link onClick={()=>setSignUp(!signUp)} href="#" variant="body2" align="center">
            {!signUp ? "Sign In" : "Sign Up"}
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VerticalModalSignIn;

const GuestLogin = () => {
  return (
    <Stack spacing={2}>
      <TextField label="Email" variant="outlined" />
      <TextField label="Password" variant="outlined" type="password" />
      <Button variant="contained" color="primary" fullWidth>
        Sign Up
      </Button>
    </Stack>
  )
}
