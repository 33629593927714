import React from "react";
import Header from "../../components/Header/muiNavbar";
import Footer from "../../components/Footer/muiFooter";
import {
  Grid,
  CssBaseline,
  Container,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts } from "../../services/apiServices";
import Loader from "../../components/Loader/loader";
import WishListItem from "./wishListItem";
import DeliverySection from "../../components/Footer/muiDeliverySection";

const WishList = () => {
  const { wishLists } = useSelector((state) => state.wishList);
  const { loading, isSuccess, response, errorMessage } = useSelector(
    (state) => state.products
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isSuccess) {
      dispatch(fetchProducts());
    }
  }, [response]);

  return (
    <div>
      <CssBaseline />
      <Header
        title={"Morail's"}
        subtitle={"A House of Creative Elegance"}
        sections={["Men's", "Women", "Home Decor", "Contact"]}
      />
      <main>
        <div
          style={{
            marginLeft: "20px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            My Wishlist
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Wishlist is not saved permanently yet. Please log in or Create
            Account to save it.
          </Typography>
        </div>
        {loading ? (
          <Loader />
        ) : !isSuccess && errorMessage ? (
          <p>{errorMessage}</p>
        ) : (
          <Container sx={{ py: 8 }} maxWidth="false">
            {response.filter((element) => wishLists.includes(element.id))
              .length === 0 ? (
              <Card variant="outlined">
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center">
                        No product in your wishlist
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" align="center">
                        Add products to wishlist to see them here.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Grid container spacing={2}>
                {response
                  .filter((element) => wishLists.includes(element.id))
                  .map((product) => (
                    <Grid item key={product.id} xs={12} sm={6} md={3}>
                      <WishListItem product={product} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Container>
        )}
      </main>
      {/* <MyPagination /> */}
      {/* <DeliverySection /> */}
      <Footer />
    </div>
  );
};

export default WishList;
