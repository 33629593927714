import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from "../../components/Header/muiNavbar";
import Footer from '../../components/Footer/muiFooter';
import MyPagination from '../../components/Pagination/Pagination';
import ImageWithTextOverlay from '../../components/Carousel/imageWithTextOverlay';
import CarouselCard from './productListImage';
import DeliverySection from '../../components/Footer/muiDeliverySection';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../../services/apiServices';
import Loader from '../../components/Loader/loader';

const newItems = [3, 4, 7]

export default function ProductListingPage() {

  const { wishLists } = useSelector((state)=>state.wishList);

  const { loading, isSuccess, response, errorMessage } = useSelector((state)=>state.products);

  const dispatch = useDispatch();

  React.useEffect(()=>{
    if(!isSuccess){
      dispatch(fetchProducts());
    }
  }, [response])

  return (
    <div>
      <CssBaseline />
      <Header title={"Morail's"} subtitle={"A House of Creative Elegance"} sections={["Men's","Women", "Home Decor", "Contact"]}/>
      <main>
        <ImageWithTextOverlay />
        {
        loading ? <Loader/> :
        !isSuccess && errorMessage ?
        <p>{errorMessage}</p> : 
        <Container sx={{ py: 8 }} maxWidth="false">
          <Grid container spacing={2}>
            {response.map(product => (
              <Grid item key={product.id} xs={12} sm={6} md={3}>
                <CarouselCard product={product} newTag={newItems.includes(product.id)} inWish={wishLists.includes(product.id)}/>
              </Grid>
            ))}
          </Grid>
        </Container>
        }
        
      </main>
      {/* <MyPagination /> */}
      <DeliverySection />
      <Footer />
      </div>
  );
}