import React from "react";
import Header from "../components/Header/muiNavbar";
import Slider from "../components/Carousel/slider";
import HomeCategory from "../components/HomeSection/catergory";
import DeliverySection from "../components/Footer/muiDeliverySection";
import Footer from "../components/Footer/muiFooter";
import CssBaseline from '@mui/material/CssBaseline';
import MuiSlider from "../components/Carousel/muiSlider";
import ImageWithTextOverlay from "../components/Carousel/imageWithTextOverlay";
import ImageGrid from "../components/HomeSection/ImageGrid";
import { IndoWestern, WorkWear, HomeDecor } from "../components/HomeSection/muiCategory";

const Home = () => {
    return (
        <React.Fragment>
        <CssBaseline />
        <Header title={"Morail's"} subtitle={"A House of Creative Elegance"} sections={["Men's","Women", "Home Decor", "Contact"]} />
        <main>
            <MuiSlider />
            {/* <HomeCategory type={"indo-western"}/>
            <HomeCategory type={"work-wear"}/>
            <HomeCategory type={"home-decor"}/> */}
            <IndoWestern />
            <WorkWear />
            <HomeDecor />
            <DeliverySection />
            <Footer />
        </main>
        </React.Fragment>
    )
}
export default Home;