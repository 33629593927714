import { configureStore } from '@reduxjs/toolkit';
import loginModalReducer from './store/loginModalReducer';
import wishListReducer from './store/wishListReducer';
import ProductSlice from './store/productSlice';
import cartSlice from './store/cartSlice';


export default configureStore({
  reducer: {
    loginModal: loginModalReducer ,
    wishList: wishListReducer,
    cart: cartSlice,
    products: ProductSlice.reducer
  }
})