import React from 'react';
import '../../assets/css/coming-soon.css';
import ComingSoonImg from '../../assets/img/coming-soon.jpg';
import logo from '../../assets/img/mc.jpg';

const ComingSoon = () => {
    return (
        <div className="coming-soon-wrapper" style={{ backgroundImage: `url(${ComingSoonImg})` }}>
        <div className="coming-soon-content">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="app-title">Morail Creations Pvt Ltd</h1>
          <h1 className="title">Coming Soon</h1>
          <p className="subtitle">A House of Creative Elegance </p>
        </div>
      </div>
    );
}

export default ComingSoon;