import React from "react";
import { Typography, Container } from "@mui/material";
import { getCurrencySymbol } from "../../utility/helper";

const styles = {
    container: {
      overflow: 'hidden',
      marginBottom: '10px', // Adjust margin as needed
    },
    leftAlign: {
      float: 'left',
      clear: 'left',
      fontWeight: 400,
      fontSize: '1.2rem'
    },
    rightAlign: {
      float: 'right',
    },
    lineSpace: {
        lineHeight: 2
    }
  };
const PaymentDetails = (props) => {
    const {cartItems} = props;
    return (
        <React.Fragment>
            <Typography variant="h6" sx={{fontWeight: 500, fontSize:'1.6rem', lineHeight: '32px'}}>Payment Details</Typography>
                <Container maxWidth="sm" sx={{marginTop: 8}}>
                    <Typography component="div">
                        <Typography variant="body1"  sx={{lineHeight: 3}}>
                            <span style={styles.leftAlign}>{"Total Item"}</span>
                            <span style={styles.rightAlign}>{cartItems.reduce((item, acc) => item + acc.quantity, 0)}</span>
                        </Typography>
                        <Typography variant="body1"  sx={{lineHeight: 3}}>
                            <span style={styles.leftAlign}>{"Total MRP"}</span>
                            <span style={styles.rightAlign}>{getCurrencySymbol('INR')} {cartItems.reduce((item, acc) => item + (acc.price * acc.quantity), 0).toFixed(2)}</span>
                        </Typography>
                        <Typography variant="body1"  sx={{lineHeight: 3}}>
                            <span style={styles.leftAlign}>{"Discount on MRP"}</span>
                            <span style={styles.rightAlign}>{getCurrencySymbol('INR')} {(cartItems.reduce((item, acc) => item + (acc.price * acc.quantity), 0) - cartItems.reduce((item, acc) => item + (acc.salePrice * acc.quantity), 0)).toFixed(2)}</span>
                        </Typography>
                        <Typography variant="body1"  sx={{lineHeight: 8}}>
                            <span style={styles.leftAlign}>{"Total Amount"}</span>
                            <span style={styles.rightAlign}>{getCurrencySymbol('INR')} {cartItems.reduce((item, acc) => item + (acc.salePrice * acc.quantity), 0).toFixed(2)}</span>
                        </Typography>
                    </Typography>
                </Container>
        </React.Fragment>
    )
}

export default PaymentDetails;