// import logoImage from '../../assets/img/mc.jpg'
const Footer = () => {
    return (
        <footer>
        <div className="footer-area footer-padding">
            <div className="container-fluid ">
                <div className="row d-flex justify-content-between">
                    <div className="col-xl-3 col-lg-3 col-md-8 col-sm-8">
                        <div className="single-footer-caption mb-50">
                            <div className="single-footer-caption mb-30">
                                <div className="footer-logo mb-35">
                                    <div style={{"display": "flex", "align-items": "center", "gap": "10px"}}>

                                        {/* <div style={{"width": "80px"}}>
                                            <img src={require('../../assets/img/mc.jpg')} alt="logo" style={{width: "100%"}} />
                                        </div> */}
                                        <h4 style={{"color": "rgb(253, 253, 253)"}}>Morail Creations</h4>

                                    </div>
                                </div>
                                <div className="footer-tittle">
                                    <div className="footer-pera">
                                        <p className="text-justify">A high-end clothing brand that offers everything from casual basics to luxurious statement pieces. If you're looking for an elegant yet bold look, this is your go-to spot for quality pieces that will stand out.</p>
                                    </div>
                                </div>
                                <div className="footer-social">
                                    <a href="#"><i className="fab fa-google"></i></a>
                                    <a href="https://bit.ly/sai4ull"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <div className="single-footer-caption mb-50">
                            <div className="footer-tittle">
                                <h4>Quick links</h4>
                                <ul>
                                    <li><a href="#">Image Licensin</a></li>
                                    <li><a href="#">Style Guide</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <div className="single-footer-caption mb-50">
                            <div className="footer-tittle">
                                <h4>Shop Category</h4>
                                <ul>
                                    <li><a href="#">Image Licensin</a></li>
                                    <li><a href="#">Style Guide</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <div className="single-footer-caption mb-50">
                            <div className="footer-tittle">
                                <h4>Pertners</h4>
                                <ul>
                                    <li><a href="#">Image Licensin</a></li>
                                    <li><a href="#">Style Guide</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4">
                        <div className="single-footer-caption mb-50">
                            <div className="footer-tittle">
                                <h4>Get in touch</h4>
                                <ul>
                                    <li><a href="#">(89) 982-278 356</a></li>
                                    <li><a href="#">demo@colorlib.com</a></li>
                                    <li><a href="#">67/A, Colorlib, Green road, NYC</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom-area">
            <div className="container">
                <div className="footer-border">
                    <div className="row d-flex align-items-center">
                        <div className="col-xl-12 ">
                            <div className="footer-copy-right text-center">
                                <p>
                                    Copyright &copy;
                                        <script>document.write(new Date().getFullYear());</script> All rights reserved | <i
                                        className="fa fa-heart" aria-hidden="true"></i> By Subramanyam And Dibyajyoti
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer;