import { createSlice } from '@reduxjs/toolkit'

export const LoginModalSlice = createSlice({
    name: "LoginReducer",
    initialState: {
        open: false
    },
    reducers: {
        handleOpen: (state, action) => {
            state.open = true;
        },
        handleClose: (state) => {
            state.open = false;
        }
    }
});


export const { handleOpen, handleClose } = LoginModalSlice.actions;

export default LoginModalSlice.reducer;