import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: "cartReducer",
    initialState: {
        items: []
    },
    reducers: {
        addToCart: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.items.find(item => item.id === newItem.id);

            if (!existingItem) {
                state.items.push({
                  ...newItem,
                  quantity: 1,
                });
              } else {
                existingItem.quantity++;
              }
        }, 
        addQuantity: (state, action) => {
            const idToIncrement = action.payload;
            const existingItem = state.items.find(item => item.id === idToIncrement);

            if (existingItem) {
                existingItem.quantity++;
            }
        },
        removeFromCart: (state, action) => {
            const idToRemove = action.payload;
            state.items = state.items.filter(item => item.id !== idToRemove);
        },
        removeQuantity(state, action) {
            const idToDecrement = action.payload;
            const existingItem = state.items.find(item => item.id === idToDecrement);
      
            if (existingItem && existingItem.quantity > 1) {
              existingItem.quantity--;
            }
        },
    }
});


export const { addToCart, removeFromCart, addQuantity, removeQuantity } = cartSlice.actions;

export default cartSlice.reducer;