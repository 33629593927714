import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Button, Card, CardMedia, CssBaseline, Box, Divider, Chip, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Header from '../../components/Header/muiNavbar';
import DeliverySection from '../../components/Footer/muiDeliverySection';
import Footer from '../../components/Footer/muiFooter';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../../services/apiServices';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
import { getCurrencySymbol } from '../../utility/helper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { addToWish, removeFromWish } from '../../store/wishListReducer';
import { addToCart } from '../../store/cartSlice';

const keyStyle = { color: '#969595', fontWeight: 300, opacity: 1, fontSize: '14px' }; // Style for keys


const ProductDetails = () => {
  const { slug } = useParams();

  const dispatch = useDispatch();

  const [product, setProduct] = useState({});

  const { loading, isSuccess, response, errorMessage } = useSelector((state)=>state.products);

  const { wishLists } = useSelector((state) => state.wishList);

  useEffect(()=>{
    if(isSuccess){
      const productData = response.find(item => item.slug === slug);
      setProduct(productData);
    }else{
      dispatch(fetchProducts());
    }
  }, [response])

  const handleWishList = () => {
    if(wishLists.includes(product.id)){
      dispatch(removeFromWish(product.id));
    }else{
      dispatch(addToWish(product.id));
    }
  }

  const addToCartHandler = () => {
    dispatch(addToCart(product));
  }

  return (
    <React.Fragment>
    {loading ? <Loader/> :
    !isSuccess && errorMessage ?
    <p>{errorMessage}</p> : 
    <Box sx={{ border: "1px solid #ccc", padding: "20px" }}>
        <Grid container spacing={2}>
      {/* Left column with product images */}
      <Grid item xs={12} md={8} sm={12}>
        <Grid container spacing={2}>
          {product?.images?.map((image, index) => (
            <Grid key={index} item xs={6} sm={6} md={6}>
              <Card sx={{ marginBottom: '10px' }}>
                <CardMedia component="img" image={image} alt={`Product Image ${index}`}  sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  transition: 'transform 0.5s ease',
                  '&:hover': {
                    transform: 'scale(1.5)', // Adjust the scale for the zoom effect
                  },
                }}/>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Right column with product description and buy/add to cart options */}
      <Grid item xs={12} md={4} sm={12}>
        <Grid container>
        <Grid item xs={6}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            sx={{
              fontSize: "1.2rem",
              color: "#969595",
              textTransform: "uppercase",
              opacity: 1,
              fontWeight: 300,
            }}
          >
            {product.brand}
          </Typography>
        </Grid>  
        <Grid item xs={6} container justifyContent="flex-end">
        <IconButton aria-label="add to favorites" onClick={handleWishList}>
          <FavoriteIcon sx={{ color: wishLists.includes(product.id) ? "red" : "primary" }} />
        </IconButton>
        <IconButton>
          <ShareIcon />
        </IconButton>
      </Grid>   
        </Grid>
      
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.4rem",
            textTransform: "capitalize",
            fontWeight: "400",
            lineHeight: "1.42",
            wordBreak: "break-word",
            margin: "0 0 4px",
            display: "inline-block",
            borderBottom: "none",
          }}
        >
          {product.name}
        </Typography>
        {product.hasOwnProperty("salePrice") ? (
          <div>
            <Typography
              variant="body1"
              display="inline"
              sx={{
                margin: "4px 0 0",
                fontSize: "1.2rem",
                textDecoration: "line-through",
                color: "grey",
              }}
            >
              {getCurrencySymbol(product.currency)} {product.price}
            </Typography>
            <Typography
              variant="body1"
              display="inline"
              sx={{ margin: "4px 6px 4px", fontSize: "1.2rem", color: "red" }}
            >
              {getCurrencySymbol(product.currency)} {product.salePrice}
            </Typography>
          </div>
        ) : (
          <Typography
            variant="body1"
            display="inline"
            sx={{ margin: "4px 0 0", fontSize: "1.2rem" }}
          >
            {getCurrencySymbol(product.currency)} {product.price}
          </Typography>
        )}
        <Divider
          sx={{
            backgroundColor: 'white', // Change this to the desired color
            height: '2px', // Adjust the height if needed
            margin: '20px 0', // Add margin as required
          }}
        />
        <Typography variant="subtitle1">COLOURS</Typography>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", margin: "6px"}}>
          {product?.colors?.map((color, index) => (
            <Box
              key={index}
              sx={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: color,
                marginRight: 2,
                marginTop: 1,
                ...(product?.color === color && {border: '2px solid white'})
              }}
              title={color}
            />
          ))}
        </Box>
        <Divider
          sx={{
            backgroundColor: 'white', // Change this to the desired color
            height: '2px', // Adjust the height if needed
            margin: '20px 0', // Add margin as required
          }}
        />
        <Typography variant="subtitle1">SIZE</Typography>
        {product?.sizes?.map((size, index) => (
            <Chip key={index} label={size} style={{ marginRight: 5, marginTop: '6px' }} />
        ))}
        <Divider
          sx={{
            backgroundColor: 'white', // Change this to the desired color
            height: '2px', // Adjust the height if needed
            margin: '20px 0', // Add margin as required
          }}
        />
        
        <Button variant="contained" color="secondary" sx={{ marginTop: '10px' }} size="large" onClick={addToCartHandler}>
          Add to Cart
        </Button>
        <Accordion sx={{ marginTop: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{
              display: 'inherit-block',
              minHeight: '1.6rem',
              margin: 0,
              wordBreak: 'break-word',
              fontSize: '14px',
              fontWeight: 400
            }}>Product Details and Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography component="div">
          <Typography variant="subtitle1" sx={{lineHeight: 3}}>
            <span style={keyStyle}>Name:</span> {product.name}
          </Typography>
          <Typography variant="body1" sx={{lineHeight: 3}}>
            <span style={keyStyle}>SKU:</span> {product.sku}
          </Typography>
          <Typography variant="body1" sx={{lineHeight: 'inherit'}}>
            <span style={keyStyle}>Description:</span> {product.description || "Presented in a light teal shade, this t-shirt from ETA will enliven your basics. Constructed in a slim contour from a textured pure-cotton material, this full-sleeved creation is rounded at the neckline and comes with a buttoned placket for practicality."}
          </Typography>
          <Typography variant="body1" sx={{lineHeight: 3}}>
            <span style={keyStyle}>Country of Origin:</span> {"India"}
          </Typography>
          <Typography variant="body1" sx={{lineHeight: 3}}>
            <span style={keyStyle}>Designer:</span> {"Morails"}
          </Typography>
        </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginTop: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{
              display: 'inherit-block',
              minHeight: '1.6rem',
              margin: 0,
              wordBreak: 'break-word',
              fontSize: '14px',
              fontWeight: 400
            }}>Delivery, Return & Exchange Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography component="ul">
          <Typography component="li">
            <a href="#link1">Terms & Conditions</a>
          </Typography>
          <Typography component="li">
            <a href="#link2">Privacy Policy</a>
          </Typography>
          <Typography component="li">
            <a href="#link3">Return Policy</a>
          </Typography>
        </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
    </Box>
    }
    </React.Fragment>
   
  );
};


const ProductDescriptionPage = () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Header title={"Morail's"} subtitle={"A House of Creative Elegance"} sections={["Men's","Women", "Home Decor", "Contact"]}/>
            <ProductDetails />
            <DeliverySection />
            <Footer />
        </React.Fragment>
    )
}

export default ProductDescriptionPage;
