import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Link,
  Box,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useScrollTrigger,
  Slide,
  useMediaQuery,
  useTheme,
  Divider
} from '@mui/material';
import { Favorite, ShoppingBasket, AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import VerticalModalSignIn from '../../pages/authentication/login_dialog';
import { handleOpen } from '../../store/loginModalReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const HeaderIcons = ({wishLists, navigate, items, dispatch}) => {
  return (
    <React.Fragment>
      <IconButton color="inherit">
        <Badge badgeContent={wishLists.length} color="error" onClick={() => navigate('/wishlist')}>
          <Favorite />
        </Badge>
      </IconButton>
      <IconButton color="inherit">
        <Badge badgeContent={items.reduce((item, acc) => item + acc.quantity, 0)} color="error" onClick={() => navigate('/cart')}>
          <ShoppingBasket />
        </Badge>
      </IconButton>
      <IconButton color="inherit">
        <AccountCircle onClick={() => dispatch(handleOpen())} />
        <VerticalModalSignIn />
      </IconButton>
    </React.Fragment>
  )
}

const Header = (props) => {
  const { sections, subtitle } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { wishLists } = useSelector((state) => state.wishList);
  const { items } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollDown, setScrollDown] = useState(true);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleScroll = () => {
    const scrolled = window.scrollY;
    // Set showLogoIcons based on the scroll position
    setScrollDown(scrolled < 50); // Adjust the threshold as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar position="sticky" sx={{ backgroundColor: !scrollDown ? 'transparent' : 'black', boxShadow: 'none' }}>
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <a onClick={(e)=>{e.preventDefault();navigate("/")}} style={{cursor: 'pointer'}}>
              <img
                className="logo-icon"
                src={require('../../assets/img/logo_1.png')}
                alt="Logo"
                style={{ width: isMobile ? '40px' : '70px', marginRight: '10px', display: isMobile ? 'block' : !scrollDown ? 'none' : 'block' }}
              />
            </a>

            <div style={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="h4">
                <span style={{ fontFamily: 'Hurme-Geometric-Sans1', fontSize: isMobile ? '20px' : '40px', textTransform: 'uppercase' }}>
                  Morail
                </span>
                <span style={{ fontFamily: 'Chalisa Octavia', fontSize: isMobile ? '20px' : '40px' }}>'s</span>
              </Typography>
              <Typography variant="subtitle1" sx={{ fontFamily: 'jane-austen', fontSize: isMobile ? '10px' : '20px', color: 'rgb(98, 97, 97)' }}>
                {subtitle}
              </Typography>
            </div>

            {scrollDown && (
              <HeaderIcons wishLists={wishLists} navigate={navigate} items={items} dispatch={dispatch}/>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {/* Mobile Drawer */}
      <Drawer 
        anchor="left" 
        open={drawerOpen} 
        onClose={handleDrawerClose}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>
          {/* Logo */}
          <ListItem
            sx={{
              p: 2,
              flexShrink: 0,
              '& img': {
                width: '70px',
                marginRight: '10px',
              },
            }}
          >
            <a onClick={(e)=>{e.preventDefault();navigate("/")}} style={{cursor: 'pointer'}}>
              <img className="logo-icon" src={require('../../assets/img/logo_1.png')} alt="Logo" />
            </a>
          </ListItem>

          {/* Menu Items */}
          {sections.map((section, key) => (
            <ListItem
              key={key}
              sx={{
                p: 2,
                flexShrink: 0,
                textTransform: 'uppercase',
                textDecoration: 'none',
                '&:hover': {
                  cursor: 'pointer',
                },
                color: 'rgb(217, 175, 89)',
                fontFamily: 'Century Gothic Regular',
                fontSize: '18.5px',
              }}
              onClick={() => {
                handleDrawerClose();
                navigate(section.url);
              }}
            >
              <ListItemText primary={section} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Divider  sx={{ backgroundColor: 'white' }}/>
      {/* Second AppBar */}
      <AppBar position="sticky">
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'black',
          display: {xs: 'none', sm: 'flex'}
        }}
      >
        {/* Left side - Logo */}
        <div>
          <Link sx={{display: scrollDown ? 'none': 'block'}} onClick={(e)=>{e.preventDefault();navigate("/")}} style={{cursor: 'pointer'}}>
            <img
              className="logo-icon"
              src={require('../../assets/img/logo_1.png')}
              alt="Logo"
              style={{ width: '60px', marginRight: '10px', paddingLeft: '10px' }}
            />
          </Link>
        </div>
        {/* Center - Menu Items */}

        <div>
        {sections.map((section) => (
          <Link
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            sx={{
              p: 1,
              paddingRight: 4,
              flexShrink: 0,
              textTransform: 'uppercase',
              textDecoration: 'none',
              '&:hover': {
                cursor: 'pointer',
              },
              color: 'rgb(217, 175, 89)',
              fontFamily: 'Century Gothic Regular',
              fontSize: '18.5px',
              marginRight: '15px', // Add margin between section links
            }}
          >
            {section}
          </Link>
        ))}
        </div>

        {/* Right side - Icons */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{display: scrollDown ? 'none': 'block'}}>
          <HeaderIcons wishLists={wishLists} navigate={navigate} items={items} dispatch={dispatch}/>
          </div>
        </div>
      </Toolbar>
    </AppBar>
    </React.Fragment>
  );
};

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Header;
