import services1 from "../../assets/img/icon/services1.svg";
import services2 from "../../assets/img/icon/services2.svg";
import services3 from "../../assets/img/icon/services3.svg";
import services4 from "../../assets/img/icon/services4.svg";
const DeliverySection = () => {
    return (
        <div className="categories-area section-padding40 gray-bg">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                        <div className="cat-icon">
                            <img src={services1} alt="" />
                        </div>
                        <div className="cat-cap">
                            <h5>Fast & Free Delivery</h5>
                            <p>Free delivery on all orders</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                        <div className="cat-icon">
                            <img src={services2} alt="" />
                        </div>
                        <div className="cat-cap">
                            <h5>Fast & Free Delivery</h5>
                            <p>Free delivery on all orders</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="single-cat mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                        <div className="cat-icon">
                            <img src={services3} alt="" />
                        </div>
                        <div className="cat-cap">
                            <h5>Fast & Free Delivery</h5>
                            <p>Free delivery on all orders</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="single-cat wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                        <div className="cat-icon">
                            <img src={services4} alt="" />
                        </div>
                        <div className="cat-cap">
                            <h5>Fast & Free Delivery</h5>
                            <p>Free delivery on all orders</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default DeliverySection;