import { Box, Grid, Typography, IconButton } from "@mui/material";

export const IndoWestern = (props) => {
  return (
    <Box sx={{ border: "1px solid #ccc", padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={require(`../../assets/img/gallery/logo_1.png`)}
              alt={`Image logo`}
              width={180}
              height={180}
              style={{ marginTop: "50%" }}
            />
            <Typography variant="h4" sx={{ fontFamily: "Lucian-CAT", marginBottom: '50%' }}>
              Indo Western
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <img
              src={require(`../../assets/img/gallery/5.png`)}
              alt={`Image 1`}
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img
              src={require(`../../assets/img/gallery/4.png`)}
              alt={`Image 2`}
              width="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const WorkWear = (props) => {
    return (
        <Box sx={{ border: "1px solid #ccc", padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <img
                  src={require(`../../assets/img/gallery/6.jpg`)}
                  alt={`Image 1`}
                  width="100%"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require(`../../assets/img/gallery/logo_1.png`)}
                  alt={`Image logo`}
                  width={180}
                  height={180}
                  style={{ marginTop: "50%" }}
                />
                <Typography variant="h4" sx={{ fontFamily: "Century Gothic Regular", marginBottom: '50%' }}>
                  Work Wear
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <img
                  src={require(`../../assets/img/gallery/7.jpg`)}
                  alt={`Image 2`}
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
};

export const HomeDecor = (props) => {
    return (
        <Box sx={{ border: "1px solid #ccc", padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1}>    
              <Grid item xs={12} sm={6} md={4}>
                <img
                  src={require(`../../assets/img/gallery/8.png`)}
                  alt={`Image 1`}
                  width="100%"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <img
                  src={require(`../../assets/img/gallery/9.png`)}
                  alt={`Image 2`}
                  width="100%"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require(`../../assets/img/gallery/logo_1.png`)}
                  alt={`Image logo`}
                  width={180}
                  height={180}
                  style={{ marginTop: "50%" }}
                />
                <Typography variant="h4" sx={{ fontFamily: "Gabriola", marginBottom: '50%' }}>
                  Home Decor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
};
