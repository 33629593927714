import React from 'react';
import services1 from "../../assets/img/icon/services1.svg";
import services2 from "../../assets/img/icon/services2.svg";
import services3 from "../../assets/img/icon/services3.svg";
import services4 from "../../assets/img/icon/services4.svg";
import { Grid, Paper, Typography } from '@mui/material';

const deliveryData = [
    {heading: 'Fast & Free Delivery', subHeading: 'Free delivery on all orders', icon: services1},
    {heading: 'Fast & Free Delivery', subHeading: 'Free delivery on all orders', icon: services2},
    {heading: 'Fast & Free Delivery', subHeading: 'Free delivery on all orders', icon: services3},
    {heading: 'Fast & Free Delivery', subHeading: 'Free delivery on all orders', icon: services4},
]

const DeliverySection = () => {
  return (
    <Grid container spacing={2} sx={{ backgroundColor: 'white' }}>
      {deliveryData.map((dData, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{paddingTop:'20px', paddingBottom:'40px'}}>
              <Grid item xs={12}>
                {/* Replace this IMG with your image */}
                <img src={dData.icon} alt={`Image ${index}`} width="100" height="100" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" mt={2} sx={{color:'black'}}>
                  {dData.heading}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{color:'black'}}>
                  {dData.subHeading}
                </Typography>
              </Grid>
            </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default DeliverySection;
