import React from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Link, ListSubheader } from '@mui/material';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import FooterCopyRight from './muiFooterCopyRight';

const footerData = [
    {heading: 'Quick Links', links: [{name: 'Image License', url: '#'}, {name: 'Style Guide', url: '#'}, {name: 'Style Guide', url: '#'}]},
    {heading: 'Shop Category', links: [{name: 'Image License', url: '#'}, {name: 'Style Guide', url: '#'}, {name: 'Style Guide', url: '#'}]},
    {heading: 'Partners', links: [{name: 'Image License', url: '#'}, {name: 'Style Guide', url: '#'}, {name: 'Style Guide', url: '#'}]},
    {heading: 'Get in touch', links: [{name: 'info@morailcreations.com', url: '#'}, {name: 'Regd. Address Plot No 1393/1400, D/7, Mason Paradise, Injana, Kalarahanga, Bhubaneswar, 751024, Odisha, India', url: '#'}]},
]

const Footer = () => {
  return (
    <React.Fragment>
        <Grid container spacing={2} sx={{ bgcolor: 'black', color: 'white', padding: '40px' }}>
      {/* First Column: Heading, Description, and Social Icons */}
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="h6" gutterBottom sx={{fontFamily:'Afterglow-Regular'}}>
          Morail Creations Pvt. Ltd
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ paddingBottom: '20px', maxWidth:'400px', textAlign: 'justify', fontFamily: 'Century Gothic Regular'  }}>
        A high-end clothing brand that offers everything from casual basics to luxurious statement pieces. If you're looking for an elegant yet bold look, this is your go-to spot for quality pieces that will stand out.
        </Typography>
        <Grid container spacing={2} alignItems="center" sx={{padding: '10px'}}>
          <Link href="#" color="inherit" sx={{borderRadius: '50%', marginRight:'10px'}}>
            <Facebook />
          </Link>
          <Link href="#" color="inherit" sx={{borderRadius: '50%', marginRight:'10px'}}>
            <Twitter />
          </Link>
          <Link href="#" color="inherit" sx={{borderRadius: '50%', marginRight:'10px'}}>
            <Instagram />
          </Link>
        </Grid>
      </Grid>

      {/* Second Column: Four sub-columns of List of Links */}
      <Grid item xs={12} sm={6} md={9}>
        <Grid container spacing={2} >
          {footerData.map((data, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
                {/* <Typography variant="h6">{data.heading}</Typography> */}
              <List
              component="nav"
              subheader={
                <ListSubheader component="div" sx={{ fontSize: '1.3rem', fontFamily: 'Century Gothic Bold' }}>
                  {data.heading}
                </ListSubheader>
              }
              >
                {data.links.map((link, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={link.name} sx={{fontFamily: 'Times New Roman'}}/>
                    </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    <FooterCopyRight title={"Morail's"} description={"Made by DJ Parida & SM Mantry"} />
    </React.Fragment>
    
  );
};

export default Footer;
