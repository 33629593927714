import { createSlice } from "@reduxjs/toolkit";
import { fetchProducts } from "../services/apiServices";

const initialState = {
    loading: false,
    isSuccess: false,
    response: [],
    errorMessage: null
}

const ProductSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.isSuccess = true;
            state.response = action.payload.result;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false;
            state.isSuccess = false;
            state.errorMessage = action.payload;
        })
    }
});

export default ProductSlice;