import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProducts } from "./mockHandler";

export const fetchProducts = createAsyncThunk(
    "fetch/products", async () => {
        return new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const products = await getProducts(); // Assuming getProducts() is a function that returns a promise with the products data
              resolve(products);
            } catch (error) {
              throw error; // Handle errors as needed
            }
        }, 4000); // Simulating a 4-second delay
    });
})