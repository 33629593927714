import React, {useState, useEffect} from "react";
import Header from "../../components/Header/muiNavbar";
import Footer from "../../components/Footer/muiFooter";
import {
  CssBaseline,
  Container,
  Typography,
  IconButton,
  CardMedia,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from "react-redux";
import DeliverySection from "../../components/Footer/muiDeliverySection";
import PaymentDetails from "./paymentDetails";
import { getCurrencySymbol } from "../../utility/helper";
import { addQuantity, removeFromCart, removeQuantity } from "../../store/cartSlice";

const Cart = () => {
    const { items } = useSelector((state) => state.cart);
    const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    }
  };
  const handleQuantityChange = (event, itemId) => {
    // Handle quantity change logic here
    const selectedQuantity = event.target.value;
    // Dispatch action to update quantity in cart for itemId
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allItemIds = items.map(item => item.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleIncreaseQuantity = (itemId) => {
    dispatch(addQuantity(itemId));
  };

  const handleDecreaseQuantity = (itemId) => {
    dispatch(removeQuantity(itemId));
  };

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  }

  return (
    <div>
      <CssBaseline />
      <Header
        title={"Morail's"}
        subtitle={"A House of Creative Elegance"}
        sections={["Men's", "Women", "Home Decor", "Contact"]}
      />
      <Box sx={{ border: "1px solid #ccc", padding: "20px" }}>
        {items.length === 0 ?
            <Card variant="outlined">
            <CardContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    Your cart is empty
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    Add products to Cart to see them here.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          :
          <Grid container spacing={0}>
          <Grid item xs={12} md={8} sx={{ position: "relative" }}>
            {/* Left Column */}
            <Container maxWidth="false">
              <Typography variant="h6" gutterBottom sx={{fontWeight: 500, fontSize:'1.6rem', lineHeight: '32px'}}>
                Cart Items
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          indeterminate={
                            selectedItems.length > 0 &&
                            selectedItems.length < items.length
                          }
                          checked={selectedItems.length === items.length}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Subtotal</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item) => (
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={selectedItems.includes(item.id)}
                            onChange={(e) => handleCheckboxChange(e, item.id)}
                          />
                        </TableCell>
                        <TableCell sx={{ width: "400px" }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <CardMedia
                                component="img"
                                image={item.images[0]} // Replace with the actual image URL
                                alt={item.name}
                                style={{
                                  height: 100,
                                  width: 100,
                                  objectFit: "contain",
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#969595",
                                  textTransform: "uppercase",
                                }}
                              >
                                {item.brand}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "0.9rem",
                                  textTransform: "capitalize",
                                  fontWeight: "400",
                                  lineHeight: "1.42",
                                  wordBreak: "break-word",
                                  display: "inline-block",
                                  borderBottom: "none",
                                  cursor: "pointer",
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "14px" }}
                              >
                                
                                {item.salePrice !== item.price ?
                                <>
                                {getCurrencySymbol(item.currency)} 
                                    <span style={{
                                        textDecoration: "line-through",
                                        color: "grey",
                                        marginRight: '4px',
                                        marginLeft: '4px'
                                        }}
                                        >{item.price.toFixed(2)}
                                    </span> 
                                    {getCurrencySymbol(item.currency)} 
                                    <span style={{
                                        color: "red",
                                        marginLeft: '4px'
                                        }}
                                    >{item.salePrice.toFixed(2)}</span>
                                </>
                                : <>{getCurrencySymbol(item.currency)} <span style={{marginLeft: '4px'}}>{item.salePrice.toFixed(2)}</span></>
                                }
                                
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() => handleDecreaseQuantity(item.id)}
                          >
                            <RemoveIcon />
                          </IconButton>
                          {item.quantity}
                          <IconButton
                            onClick={() => handleIncreaseQuantity(item.id)}
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {getCurrencySymbol(item.currency)} {(item.salePrice * item.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell>
                            <IconButton sx={{cursor: 'pointer'}} onClick={()=>handleRemoveFromCart(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* Right Column */}
            <PaymentDetails cartItems={items}/>
          </Grid>
        </Grid>
        }
        
      </Box>
      {/* <MyPagination /> */}
      <DeliverySection />
      <Footer />
    </div>
  );
};

export default Cart;
