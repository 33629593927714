import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { handleOpen } from '../../store/loginModalReducer';
import { useDispatch } from 'react-redux';
import VerticalModalSignIn from '../../pages/authentication/login_dialog';
const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <header>
            <nav class="navbar nav-active fixed-top">
                <div class="container-fluid p-0 d-block">
                    <div className="row d-flex justify-content-between">
                        <div className="col-4 logo">
                            <div style={{ width: '80px' }}>
                                <a href="#"><img src={require("../../assets/img/logo_1.png")} width="80px" alt="" /></a>
                            </div>
                        </div>
                        <div className="col-4 text-center">
                            <img src={require("../../assets/img/logo_2.png")} width={"60%"} />
                        </div>
                        <div className="col-4 text-end">
                            <img src={require("../../assets/img/logo_3.png")} width={"60%"} />
                            {/* <i className="text-white" style={{ fontFamily: 'Rage Italic' }}>Hourse of Creative Elegance</i> */}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            {/* <img src={require("../../assets/img/hamburger-menu.svg")} width={"20%"} /> */}
                        </button>

                        <div class="offcanvas offcanvas-start text-start" tabindex="-1" id="offcanvasDarkNavbar"
                            aria-labelledby="offcanvasDarkNavbarLabel">
                            <div class="offcanvas-header">
                                <button type="button" class="btn-close " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                                    <li class="nav-item">
                                        <a class="nav-link fw-medium active text-uppercase" aria-current="page" href="#">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link fw-medium text-black" aria-current="page" href="#">Men</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link fw-medium text-black" href="#">Women</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link fw-medium text-black">Home Decor</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link fw-medium text-black">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class=" d-lg-block d-none header-menu-item">
                            <ul class="list-unstyled d-flex mb-2 mb-lg-0 gap-4">
                                {/* <li class="nav-item">
                                    <a class="nav-link fw-medium text-black" aria-current="page" href="#">Home</a>
                                </li> */}
                                <li class="nav-item">
                                    <a class="nav-link fw-medium text-black" href="#">Men</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fw-medium text-black">Women</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fw-medium text-black">Home Decor</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fw-medium text-black">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right1 d-flex align-items-center">
                            <div className="search d-none d-md-block">
                                <ul className="d-flex align-items-center">
                                    <li className="mr-15">
                                        <div className="nav-search search-switch">
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card-stor">
                                            <img src={require("../../assets/img/gallery/card.svg").default} alt="" />
                                            <span>0</span>
                                        </div>
                                    </li>
                                    <li className="mr-15 ml-15">
                                        <div className="nav-search search-switch">
                                           <a data-tooltip-id="my-tooltip" data-tooltip-content="Sign In" onClick={()=> dispatch(handleOpen()) }><i className="fas fa-user"></i></a> 
                                            <Tooltip id="my-tooltip" />
                                        </div>
                                        <VerticalModalSignIn />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default Header;