import { Box, Typography, IconButton, Radio, Stack, Button, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const ImageWithTextOverlay = () => {
    const navigate = useNavigate();
    const theme_ = useTheme();
    const isMobile = useMediaQuery(theme_.breakpoints.down('sm'));

  const imageData = [
    {
      src: 'https://source.unsplash.com/random?wallpapers',
      category: 'Category 1',
      description: "Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don't simply skip over it entirely.",
    },
    {
      src: 'https://source.unsplash.com/random?landscape',
      category: 'Category 2',
      description: "Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don't simply skip over it entirely.",
    },
    // Add more image data as needed
  ];

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % imageData.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? imageData.length - 1 : prevActiveStep - 1
    );
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const containerStyles = {
    position: 'relative',
    width: '100%',
    height: 700,
    bgcolor: 'background.paper',
  };

  const imageStyles = {
    width: '100%',
    height: 700,
    objectFit: 'cover',
  };

  const textStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    padding: '30px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
    width: '100%',
    bgcolor: 'rgba(128, 128, 128, 0.6)',
    fontFamily: 'Afterglow-Regular',
    [theme.breakpoints.down('sm')]: {
        padding: '15px', // Adjust the padding for smaller screens
    },
  };

  const iconStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  };

  return (
    <Box sx={containerStyles}>
      <SwipeableViews index={activeStep} onChangeIndex={setActiveStep}>
        {imageData.map((slide, index) => (
          <Box key={index} style={{ position: 'relative' }}>
            <img src={slide.src} alt={`Slide ${index}`} style={imageStyles} />
            <Box sx={textStyles}>
              <Typography variant={isMobile ? "h2" : "h1"}>{"ELEGANCE"}</Typography>
              <Typography variant={isMobile ? "h2" : "h1"}>{"NEVER"}</Typography>
              <Typography variant={isMobile ? "h2" : "h1"}>{"FADE"}</Typography>
            </Box>
          </Box>
        ))}
      </SwipeableViews>
      <Box sx={{ position: 'absolute', bottom: '50px', left: '50%', transform: 'translateX(-50%)' }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/product-list')}>
          Shop Now
        </Button>
      </Box>
      <Stack direction="row" spacing={1} sx={{ position: 'absolute', bottom: 0, width: '100%', justifyContent: 'center' }}>
        {imageData.map((_, index) => (
          <Radio
            key={index}
            sx={{color:'#d9af59'}}
            checked={activeStep === index}
            onChange={() => handleStepChange(index)}
          />
        ))}
      </Stack>
      <IconButton onClick={handleBack} style={{ ...iconStyles, left: 0 }}>
        <ArrowBackIcon />
      </IconButton>

      <IconButton onClick={handleNext} style={{ ...iconStyles, right: 0 }}>
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};

export default ImageWithTextOverlay;
