import { Box, Typography } from '@mui/material';

const ImageWithTextOverlay = () => {
    const containerStyles = {
      position: 'relative',
      width: '100%',
      height: 500,
      bgcolor: 'background.paper',
    };
  
    const imageStyles = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    };
  
    const textStyles = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      color: 'white',
    };
  
    return (
      <Box sx={containerStyles}>
        <img
          src="https://source.unsplash.com/random?wallpapers" // Replace with your image URL
          alt="Placeholder"
          style={imageStyles}
        />
        <Box sx={textStyles}>
          <Typography variant="h4">Product Category</Typography>
          <Typography variant="subtitle1">
            Something short and leading about the collection below—its contents,
            the creator, etc. Make it short and sweet, but not too short so folks
            don&apos;t simply skip over it entirely.
          </Typography>
        </Box>
      </Box>
    );
  };

export default ImageWithTextOverlay;