import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Favorite } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addToWish, removeFromWish } from "../../store/wishListReducer";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol } from "../../utility/helper";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CarouselCard = (props) => {
  const { product, newTag, inWish } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const navigate = useNavigate();

  const maxSteps = product?.images?.length;

  const dispatch = useDispatch();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleWishList = () => {
    if(inWish){
      dispatch(removeFromWish(product.id));
    }else{
      dispatch(addToWish(product.id));
    }
  }

  const handleNavigate = () => {
    navigate('/product/'+product.slug);
  }

  return (
    <Card elevation={3}>
      <Box sx={{ position: "relative" }}>
        <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
          {product?.images.map((image, index) => (
            <Box key={index} sx={{ position: "relative", overflow: "hidden" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <CardMedia
                  component="img"
                  height="450"
                  image={image}
                  alt={`Image ${index + 1}`}
                  sx={{
                    objectFit: 'cover',
                    cursor: 'pointer',
                    transition: "transform 0.3s", // Add a smooth transition for the transform effect
                    "&:hover": {
                      transform: "scale(1.1)", // Apply the scale transformation on hover
                      overflow: "hidden", // Hide the scrollbar on hover
                    },
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for webkit-based browsers
                    },
                  }}
                  onClick={handleNavigate}
                />
              ) : null}
              {index === activeStep && (
                <React.Fragment>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                    }}
                  >
                    <IconButton aria-label="add to favorites" onClick={handleWishList}>
                      <Favorite sx={{ color: inWish ? "red" : "primary" }} />
                    </IconButton>
                  </Box>
                  {newTag && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        bgcolor: "rgba(0,0,0,0.6)",
                        color: "white",
                        padding: "5px",
                        borderRadius: "5px",
                        bgcolor: "#1d2547",
                      }}
                    >
                      <Typography variant="body2">New</Typography>
                    </Box>
                  )}
                </React.Fragment>
              )}
            </Box>
          ))}
        </SwipeableViews>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            transform: "translateY(-50%)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            pointerEvents: "false", // To enable clicks on the image
          }}
        >
          <IconButton onClick={handleBack} disabled={activeStep === 0}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{
            fontSize: "12px",
            color: "#969595",
            textTransform: "uppercase",
          }}
        >
          {product.brand}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            textTransform: "capitalize",
            fontWeight: "400",
            lineHeight: "1.42",
            wordBreak: "break-word",
            margin: "0 0 4px",
            display: "inline-block",
            borderBottom: "none",
            cursor:'pointer'
          }}
          onClick={handleNavigate}
        >
          {product.name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          {product.colors.map((color, index) => (
            <Box
              key={index}
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: color,
                margin: "2px",
              }}
              title={color}
            />
          ))}
        </Box>
        {product.salePrice !== product.price ? (
          <div>
            <Typography
              variant="body1"
              display="inline"
              sx={{
                margin: "4px 0 0",
                fontSize: "14px",
                textDecoration: "line-through",
                color: "grey",
              }}
            >
              {getCurrencySymbol(product.currency)} {product.price}
            </Typography>
            <Typography
              variant="body1"
              display="inline"
              sx={{ margin: "4px 6px 4px", fontSize: "14px", color: "red" }}
            >
              {getCurrencySymbol(product.currency)} {product.salePrice}
            </Typography>
          </div>
        ) : (
          <Typography
            variant="body1"
            display="inline"
            sx={{ margin: "4px 0 0", fontSize: "14px" }}
          >
            {getCurrencySymbol(product.currency)} {product.price}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CarouselCard;
