import React from 'react';
import { Grid, Typography, Button, Card, CardMedia } from '@mui/material';

const ProductDescriptionPage = () => {
  // Sample product images
  const productImages = [
    'https://www.nicobar.com/cdn/shop/files/NBI031102_1_765x.jpg?v=16967392887',
    'https://www.nicobar.com/cdn/shop/files/NBI031102_2_765x.jpg?v=1696739290',
    // 'https://via.placeholder.com/765x957',
    // 'https://via.placeholder.com/765x957',
    // Add more image URLs as needed
  ];

  return (
    <Grid container spacing={2}>
      {/* Left column with product images */}
      <Grid item xs={8} md={8} sm={12}>
        <Grid container spacing={2}>
          {productImages.map((image, index) => (
            <Grid key={index} item xs={12} sm={6} md={6}>
              <Card sx={{ marginBottom: '10px' }}>
                <CardMedia component="img" image={image} alt={`Product Image ${index}`}  sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  transition: 'transform 0.5s ease',
                  '&:hover': {
                    transform: 'scale(1.5)', // Adjust the scale for the zoom effect
                  },
                }}/>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Right column with product description and buy/add to cart options */}
      <Grid item xs={4} md={4} sm={12}>
        <Typography variant="h4">Product Name</Typography>
        <Typography variant="subtitle1">Product Description goes here...</Typography>
        <Typography variant="h6" mt={2}>
          Price: $XX.XX
        </Typography>
        <Typography variant="body1">More details about the product...</Typography>
        <Button variant="contained" color="primary" sx={{ marginRight: '10px', marginTop: '10px' }}>
          Buy Now
        </Button>
        <Button variant="contained" color="secondary" sx={{ marginTop: '10px' }}>
          Add to Cart
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductDescriptionPage;
